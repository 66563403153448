<template>
  <Page full>
    <role-body />
  </Page>
</template>

<script>
import PageMixin from '../mixins/Page'
import RoleBody from '../bodies/Roles.vue'

export default {
  mixins: [PageMixin],
  name: 'Roles',
  data() {
    return {
      startingTab: 'Users'
    }
  },
  components: {
    RoleBody
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
