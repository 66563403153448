<template>
  <grid :isMain="isMain" :multiple="true" type="role" title="Roles" />
</template>

<script>
import Grid from '@/components/ui/Grid/Grid.vue'
import BodyMixin from '../mixins/Body'

export default {
  mixins: [BodyMixin],
  components: { Grid }
}
</script>
